import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'

import { Formik } from 'formik'
import { navigate } from 'gatsby'

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function Template({
  data: {
    site: {
      siteMetadata: { title: siteTitle },
    },
  },
}) {
  return (
    <Layout>
      <Helmet title={`${siteTitle} - Contact`} />
      <div id="pageheader">
        <h1>Contact</h1>
      </div>
      <p>
        Get in touch with Jeff. Just use the form below and he will get back to
        you shortly.
      </p>
      <Formik
        initialValues={{ name: '', url: '', email: '', message: '' }}
        validate={(values) => {
          let errors = {}
          if (!values.email) {
            errors.email = 'E-Mail address is required.'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid E-Mail address'
          }
          if (!values.name) {
            errors.name = 'Name is required.'
          }
          if (!values.message) {
            errors.message = 'A message is required.'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)
          fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({ 'form-name': 'contact', ...values }),
          })
            .then(() => {
              setSubmitting(false)
              navigate('/contact/thank-you')
            })
            .catch((error) => {
              console.error(error)
              setSubmitting(false)
            })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            name="contact"
            action="/contact/thank-you"
            method="POST"
            netlify-honeypot="url"
            data-netlify="true"
            noValidate="novalidate"
            onSubmit={handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <div className="form-group">
              <label htmlFor="name">Name *</label>
              <input
                type="text"
                name="name"
                placeholder="Name"
                required=""
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                className={`form-control ${
                  errors.name && touched.name && 'is-invalid'
                }`}
              />
              {errors.name && touched.name && (
                <small className="error">{errors.name}</small>
              )}
            </div>
            <div className="form-group" style={{ display: 'none' }}>
              Don’t fill this out if you're human:
              <input
                type="text"
                name="url"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.url}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">E-Mail *</label>

              <input
                type="text"
                name="email"
                placeholder="E-Mail"
                required=""
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className={`form-control ${
                  errors.email && touched.email && 'is-invalid'
                }`}
              />
              {errors.email && touched.email && (
                <small className="error">{errors.email}</small>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="message">Message *</label>
              <textarea
                name="message"
                rows="10"
                required=""
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                className={`form-control ${
                  errors.message && touched.message && 'is-invalid'
                }`}
              />
              {errors.message && touched.message && (
                <small className="error">{errors.message}</small>
              )}
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-lg"
              disabled={isSubmitting}
            >
              Submit
            </button>
          </form>
        )}
      </Formik>
    </Layout>
  )
}

export const pageQuery = graphql`
  query contact {
    site: site {
      siteMetadata {
        title
      }
    }
  }
`
